<template>
  <div class="base-container" v-loading>
    <el-form label-width="160px">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位编码" prop="typeId">
            <el-input v-model="unit.unitCoding" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="组织机构代码" prop="address">
            <el-input v-model="unit.organizationCode" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位名称" prop="typeId">
            <el-input v-model="unit.unitName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="使用名称" prop="address">
            <el-input v-model="unit.userName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位地址" prop="typeId">
            <el-input v-model="unit.address" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="法定代表人电话" prop="address">
            <el-input v-model="unit.legalPhone" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="法定代表人" prop="typeId">
            <el-input v-model="unit.legalName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="法定代表人身份证" prop="address">
            <el-input v-model="unit.legalIdCard" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位类型" prop="typeId">
            <el-input v-model="unit.unitTypeName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="单位其他情况" prop="address">
            <el-input v-model="unit.others_condition" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="上级单位" prop="typeId">
            <el-input v-model="unit.upUnit" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="邮政编码" prop="address">
            <el-input v-model="unit.postalCode" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="单位电话" prop="typeId">
            <el-input v-model="unit.unitPhone" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="单位传真" prop="address">
            <el-input v-model="unit.fax" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="E-Mail" prop="typeId">
            <el-input v-model="unit.email" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="营业时最大人数" prop="address">
            <el-input v-model="unit.businessMaxNum" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="经济所有制" prop="typeId">
            <el-input v-model="unit.economicOwnershipName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="单位成立时间" prop="address">
            <el-date-picker v-model="unit.foundTime" style="width:100%" readonly>
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="行政区域(区域名称)" prop="typeId">
            <el-input v-model="unit.areaName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="消防管辖" prop="address">
            <el-input v-model="unit.fireManage" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="所属行业" prop="typeId">
            <el-input v-model="unit.industryName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="火灾危险性" prop="address">
            <el-input v-model="unit.fireDangerName" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="info-title">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防负责人联系方式" prop="typeId">
            <span>姓名</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="联系电话" prop="address">
            <span>身份证</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防安全管理人" prop="typeId">
            <el-input v-model="unit.fireManagePerson" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item :label="unit.fireManagePersonTel" prop="address" class="trans-background">
            <el-input v-model="unit.fireManagePersonCard" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防安全责任人" prop="typeId">
            <el-input v-model="unit.fireSafePerson" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item :label="unit.fireSafePersonTel" prop="address" class="trans-background">
            <el-input v-model="unit.fireSafePersonCard" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <nav>单位主建筑信息</nav>
    <el-form label-width="160px" class="building">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="建筑结构" prop="typeId">
            <span>{{building.structureName}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="耐火等级" prop="address">
            <span>{{building.fireResistLevelName}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="建筑面积（㎡）" prop="typeId">
            <span>{{building.area}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="建筑高度（m）" prop="address">
            <span>{{building.height}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="建筑楼层（层）" prop="typeId">
            <span>{{building.floorNum}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="安全出口（个）" prop="address">
            <span>{{building.safeExit}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="疏散楼梯（个）" prop="typeId">
            <span>{{building.sparseElevator}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="疏散标识（个）" prop="address">
            <span>{{building.sparseFlag}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防电梯（个）" prop="typeId">
            <span>{{building.fireElevator}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="疏散应急灯（个）" prop="address">
            <span>{{building.sparseLight}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防应急车道（个）" prop="typeId">
            <span>{{building.emergencyLane}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="重点部位（所在高度）" prop="address">
            <span>{{building.importanceHeight}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="自动消防设施" prop="typeId">
            <span>{{building.importanceHeight}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="避难所楼层（层）" prop="address">
            <span>{{building.takeRefugeFloor}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="燃气类型" prop="typeId">
            <span>{{building.gasTypeName}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="避难所面积（㎡）" prop="address">
            <span>{{building.takeRefugeArea}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="储备仓库" prop="typeId">
            <span>{{building.reserveWarehouse}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="仓库位置" prop="address">
            <span>{{building.warehouseLocation}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="危险物品" prop="typeId">
            <span>{{building.dangerThing}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="消防控制室位置" prop="address">
            <span>{{building.fireControlRoomLocation}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="消防控制室面积（㎡）" prop="typeId">
            <span>{{building.fireControlRoomArea}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="疏散楼梯宽度（m）" prop="address">
            <span>{{building.sparseElevatorWidth}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="微型消防站（个）" prop="typeId">
            <span>{{building.miniFireStation}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="灭火器数量（个）" prop="address">
            <span>{{building.cutFireNum}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="防火门型号" prop="typeId">
            <span>{{building.fireDoorType}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="防火门数量（个）" prop="address">
            <span>{{building.fireDoorNum}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="防火卷帘门型号" prop="typeId">
            <span>{{building.fireShutterDoorType}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="防火卷帘门数量（个）" prop="address">
            <span>{{building.fireShutterDoorNum}}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      unit: {},
      building: {},
      loading: false
    }
  },
  created () {
    this.getUnit()
    this.getBuilding()
  },
  methods: {
    async getUnit () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      this.loading = true
      const { data: result } = await this.$axios.get('/unit/listWeb', { params: data })
      if (result.code === 200) {
        this.unit = result.data[0] || {}
      }
    },
    async getBuilding () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/building/list', { params: data })
      if (result.code === 200) {
        this.building = result.data[0] || {}
        this.loading = false
      } else {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.base-container {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  .el-form {
    .el-form-item {
      border: 1px solid #ddd;
      border-bottom: none;
      margin: 0;
      .el-form-item__label {
        background-color: rgba(30, 74, 115, 0.5);
        color: #fff;
      }
      .el-input__inner {
        border: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-radius: 0;
        background-color: rgba(7, 104, 159, 0.15);
        color: #fff;
      }
      .el-date-picker {
        border: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-radius: 0;
      }
    }
    .el-row:last-child {
      .el-form-item {
        border-bottom: 1px solid #ddd;
      }
    }
    .el-row {
      .el-col:first-child {
        padding-right: 0 !important;
        .el-form-item {
          border-right: none;
        }
      }
      .el-col:last-child {
        padding-left: 0 !important;
        .el-form-item {
          border-left: none;
          .el-input__inner {
            border-right: none;
          }
          .el-date-picker {
            border-right: none;
          }
        }
        .trans-background {
          .el-form-item__label {
            background-color: rgba(30, 74, 115, 0.5);
          }
        }
      }
    }
    .info-title {
      .el-col {
        .el-form-item {
          .el-form-item__label {
            background-color: #23527e;
            color: #fff;
            text-align: center;
            border-right: 1px solid #ddd;
          }
          background-color: #23527e;
          color: #fff;
          border-left: 1px solid #ddd !important;
        }
      }
    }
  }
  nav {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(
      90deg,
      rgba(35, 82, 126, 0.6) 0%,
      rgba(57, 184, 230, 0.3) 100%
    );
    text-align: center;
    color: #fff;
  }
  .building {
    .el-form-item__label {
      background-color: rgba(30, 74, 115, 0.5);
      color: #fff;
      border-right: 1px solid #ddd;
    }
    .el-row {
      .el-col:nth-child(2n) {
        .el-form-item {
          .el-form-item__label {
            border-left: 1px solid #ddd;
          }
        }
      }
    }
    span {
      color: #fff;
    }
  }
}
</style>
